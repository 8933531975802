import { useEffect, useRef, RefObject } from "react";

export function useScrollToBottom<T extends HTMLElement>(): [
  RefObject<T | null>,
  RefObject<T | null>,
] {
  const containerRef = useRef<T>(null);
  const endRef = useRef<T>(null);

  useEffect(() => {
    const container = containerRef.current;
    const end = endRef.current;

    if (!container || !end) return;

    let isUserScrolling = false;
    let lastScrollTop = container.scrollTop;

    // Check if user is near bottom (within 100px)
    const isNearBottom = () => {
      const threshold = 100;
      return container.scrollHeight - container.scrollTop - container.clientHeight < threshold;
    };

    // Handle scroll events to detect user scrolling
    const handleScroll = () => {
      if (container.scrollTop !== lastScrollTop) {
        isUserScrolling = true;
        setTimeout(() => {
          isUserScrolling = false;
        }, 150); // Reset after scroll stops
      }
      lastScrollTop = container.scrollTop;
    };

    const observer = new MutationObserver((mutations) => {
      // Check if the mutation is related to a chart or other interactive element
      const isChartMutation = mutations.some(mutation => {
        // Check if the mutation target or its parents are part of a chart
        let node: Node | null = mutation.target;
        while (node) {
          if (node instanceof Element) {
            if (
              node.classList?.contains('recharts-wrapper') ||
              node.classList?.contains('recharts-tooltip') ||
              node.classList?.contains('ChartContainer') ||
              node.classList?.contains('force-graph-container') ||
              node.classList?.contains('graph-tooltip')
            ) {
              return true;
            }
          }
          node = node.parentNode;
        }
        return false;
      });

      // Only auto-scroll if:
      // 1. User isn't actively scrolling
      // 2. This isn't a chart-related mutation
      // 3. User was already near bottom OR this is a new message
      const shouldScroll = !isUserScrolling && 
        !isChartMutation &&
        (isNearBottom() || 
          mutations.some(mutation => 
            mutation.addedNodes.length > 0 && 
            (mutation.addedNodes[0] as Element).classList?.contains('message-container')
          )
        );

      if (shouldScroll) {
        end.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    });

    container.addEventListener('scroll', handleScroll);

    observer.observe(container, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });

    return () => {
      observer.disconnect();
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [containerRef, endRef];
}

"use client";

import * as React from "react";
import {
  getVectorStores,
  canCreateVectorStore,
  createVectorStore,
  updateVectorStore,
  updateStorePermissions,
  deleteVectorStore,
} from "@/app/actions/data-actions";
import { Switch } from "@/components/ui/switch";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Database, Plus, Pencil, ChartNetwork } from "lucide-react";
import { useDataStore } from "@/app/store/dataStore";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/hooks/use-toast";
import { useSession } from "next-auth/react";
import { DataModal } from "@/components/data-modal";
import { DataCreateModal } from "@/components/data-create-modal";
import { Separator } from "@/components/ui/separator";
import { useRouter } from "next/navigation";
import { getUserRole } from "@/app/actions/admin-actions";

export function DataSelect() {
  const { data: session } = useSession();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [canCreate, setCanCreate] = React.useState(false);
  const { toast } = useToast();
  const router = useRouter();
  const [userRole, setUserRole] = React.useState<string | null>(null);


  const {
    vectorStores,
    setVectorStores,
    selectedStores,
    toggleStore,
    isStoreSelected,
    selectedStore,
    setSelectedStore,
    isCreateModalOpen,
    setIsCreateModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
    isProcessing,
    setIsProcessing,
  } = useDataStore();

  React.useEffect(() => {
    getVectorStores()
      .then((fetchedStores) => {
        setVectorStores(fetchedStores);
      })
      .catch(console.error);

    canCreateVectorStore().then(setCanCreate).catch(console.error);

    getUserRole(session?.user?.id || "").then(setUserRole).catch(console.error);
  }, [setVectorStores]);

  const handleCreateSubmit = async (formData: {
    name: string;
    description: string;
    default_selected: boolean;
    type: string;
  }) => {
    setIsProcessing(true);
    try {
      const store = await createVectorStore(formData);
      await updateStorePermissions(store.id, []);
      setVectorStores([...vectorStores, store]);

      setIsCreateModalOpen(false);
      toast({
        title: "Success",
        description: "Vector store created successfully",
      });

      setSelectedStore(store);
      setIsEditModalOpen(true);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create vector store",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleEditSubmit = async (formData: {
    id: number;
    name: string;
    description: string;
    default_selected: boolean;
    roleIds: number[];
  }) => {
    setIsProcessing(true);
    try {
      const store = await updateVectorStore(formData.id, formData);
      await updateStorePermissions(formData.id, formData.roleIds);
      setVectorStores(vectorStores.map((s) => (s.id === store.id ? store : s)));

      setIsEditModalOpen(false);
      setSelectedStore(undefined);
      toast({
        title: "Success",
        description: "Vector store updated successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update vector store",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDelete = async (vectorStoreId: number) => {
    try {
      await deleteVectorStore(vectorStoreId);
      toast({
        title: "Success",
        description: "Vector store deleted successfully",
      });
      setIsEditModalOpen(false);
      getVectorStores()
        .then((fetchedStores) => {
          setVectorStores(fetchedStores);
        })
        .catch(console.error);
      router.refresh();
    } catch (error) {
      console.error("Failed to delete store:", error);
      toast({
        title: "Error",
        description: "Failed to delete store",
        variant: "destructive",
      });
    }
  };

  const filteredStores = vectorStores.filter((store) =>
    store.name.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="justify-between"
            size="sm"
          >
            <div className="flex w-full items-center gap-2">
              <Database size={14} />
              <div className="flex items-center gap-2 overflow-visible relative">
                Data
                <Badge className="rounded-full text-xs h-4 w-4 p-0 flex items-center justify-center z-40">
                  {selectedStores.length}
                </Badge>
              </div>
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[600px] p-4">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <Input
                placeholder="Search data sources..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="flex-1"
              />
              {canCreate && (
                <Button
                  variant="outline"
                  size="sm"
                  className="ml-2"
                  onClick={() => setIsCreateModalOpen(true)}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  New Store
                </Button>
              )}
            </div>
            <div className="flex flex-col gap-4">
              {filteredStores.map((store, index) => (
                <React.Fragment key={store.id}>
                  {index > 0 && <Separator />}
                  <div className="flex flex-col w-full transition-colors">
                    <div className="flex items-center justify-between w-full">
                      <div className="space-y-0.5 flex items-center gap-2">
                        {store.type === "graph_store" ? (
                          <ChartNetwork size={14} />
                        ) : (
                          <Database size={14} />
                        )}
                        <div className="text-sm font-medium">{store.name}</div>
                      </div>
                      <div className="flex items-center gap-2">
                        {(store.userId === session?.user?.id || userRole === "admin") && (
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => {
                              setSelectedStore(store);
                              setIsEditModalOpen(true);
                            }}
                          >
                            <Pencil
                              className="h-3 w-3 text-muted-foreground"
                              size={14}
                            />
                          </Button>
                        )}
                        <Switch
                          checked={isStoreSelected(store.id)}
                          onCheckedChange={() => toggleStore(store)}
                        />
                      </div>
                    </div>
                    <div className="text-xs text-muted-foreground ">
                      {store.description}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            {filteredStores.length === 0 && (
              <div className="text-center text-sm text-muted-foreground">
                No data sources found
              </div>
            )}
          </div>
        </PopoverContent>
      </Popover>

      <DataCreateModal
        isOpen={isCreateModalOpen}
        onOpenChange={setIsCreateModalOpen}
        onSubmit={handleCreateSubmit}
        isProcessing={isProcessing}
      />

      <DataModal
        isOpen={isEditModalOpen}
        onOpenChange={setIsEditModalOpen}
        onSubmit={handleEditSubmit}
        store={selectedStore}
        isProcessing={isProcessing}
        onDelete={handleDelete}
      />
    </>
  );
}

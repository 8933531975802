"use client";

import * as React from "react";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import {
  getDocuments,
  deleteDocument,
  type Document,
} from "@/app/actions/document-actions";
import { Trash2Icon, Search, Loader2Icon } from "lucide-react";
import { GeistSans } from "geist/font/sans";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";

interface DataDisplayProps {
  storeId: number | string;
  onRefreshRequest?: () => void;
}

export type DataDisplayRef = {
  refresh: () => Promise<void>;
};

export const DataDisplay = React.forwardRef<DataDisplayRef, DataDisplayProps>(
  function DataDisplay({ storeId, onRefreshRequest }, ref) {
    const [documents, setDocuments] = React.useState<Document[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isDeleting, setIsDeleting] = React.useState<string | null>(null);
    const [searchQuery, setSearchQuery] = React.useState("");

    const fetchDocuments = React.useCallback(async () => {
      if (!storeId) {
        setDocuments([]);
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const docs = await getDocuments(storeId);
        setDocuments(docs);
      } catch (error) {
        console.error("Failed to fetch documents:", error);
        setDocuments([]);
      } finally {
        setIsLoading(false);
      }
    }, [storeId]);

    React.useEffect(() => {
      fetchDocuments();
    }, [fetchDocuments]);

    React.useImperativeHandle(
      ref,
      () => ({
        refresh: fetchDocuments,
      }),
      [fetchDocuments],
    );

    const handleDelete = async (documentTitle: string) => {
      try {
        setIsDeleting(documentTitle);
        await deleteDocument(storeId, documentTitle);
        await fetchDocuments();
        if (onRefreshRequest) {
          onRefreshRequest();
        }
      } catch (error) {
        console.error("Failed to delete document:", error);
      } finally {
        setIsDeleting(null);
      }
    };

    const filteredDocuments = React.useMemo(() => {
      if (!searchQuery) return documents;
      return documents.filter((doc) =>
        doc.documentTitle.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }, [documents, searchQuery]);

    const hasPendingDocuments = React.useMemo(() => {
      return documents.some((doc) => doc.status === "pending");
    }, [documents]);

    React.useEffect(() => {
      let intervalId: NodeJS.Timeout;

      if (hasPendingDocuments) {
        intervalId = setInterval(() => {
          console.log("Fetching documents...");
          fetchDocuments();
        }, 5000);
      }

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }, [hasPendingDocuments, fetchDocuments]);

    return (
      <div className="space-y-4">
        <div className="relative">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search documents..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-8"
          />
        </div>
        <ScrollArea className="h-[350px] w-full">
          <Table>
            <TableBody>
              {filteredDocuments.map((doc) => (
                <TableRow key={doc.documentTitle}>
                  <TableCell
                    className={`${GeistSans.className} truncate max-w-[300px]`}
                  >
                    {doc.documentTitle}
                  </TableCell>
                  <TableCell className="w-[100px]">
                    {doc.status === "pending" ? (
                      <div className="flex items-center gap-2">
                        <Loader2Icon className="w-4 h-4 animate-spin" />
                        <span className="text-muted-foreground text-xs">
                          Transcribing...
                        </span>
                      </div>
                    ) : (
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDelete(doc.documentTitle)}
                        disabled={isDeleting === doc.documentTitle}
                      >
                        {isDeleting === doc.documentTitle ? (
                          "Deleting..."
                        ) : (
                          <Trash2Icon className="w-4 h-4" />
                        )}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {filteredDocuments.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    className="text-center text-muted-foreground"
                  >
                    {documents.length === 0
                      ? "No documents found"
                      : "No matching documents found"}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>
    );
  },
);

DataDisplay.displayName = "DataDisplay";

"use client";

import { usePathname } from "next/navigation";
import { ThemeToggle } from "@/components/theme-toggle";
import { useSession } from "next-auth/react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LogOut, List, Hammer, Database, Settings } from "lucide-react";
import { signOut } from "next-auth/react";
import { ModelSelect } from "./model-select";
import { Button } from "./ui/button";
import { getUserRole } from "@/app/actions/user-actions";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { ToolSelect } from "./tool-select";
import { DataSelect } from "./data-select";

export function Navbar() {
  const { data } = useSession({
    required: true,
  });

  const router = useRouter();

  const [userRole, setUserRole] = useState<{ roleId: number; roleName: string; roleDescription: string | null } | null>(null);
  useEffect(() => {
    getUserRole().then((role) => {
      setUserRole(role);
    });
  }, []); 

  const user = data?.user;
  const pathname = usePathname();

  return (
    <nav className="">
      <div className="fixed top-0 left-0 w-full h-20 bg-gradient-to-b from-background to-transparent z-100"></div>
      <div className="fixed top-[1px] left-24 mt-2 flex items-center gap-3">
        <ModelSelect />
        <ToolSelect />
        <DataSelect />
      </div>
      <div className="fixed top-0 right-4 mt-2">
        <div className="flex gap-2 items-center">
          <ThemeToggle />
          <DropdownMenu>
            <DropdownMenuTrigger className="cursor-pointer rounded-full">
              <Avatar className="h-7 w-7 shadow border">
                <AvatarImage src={user?.image ?? ""} />
                <AvatarFallback className="text-xs font-bold">
                  {user?.name?.[0]}
                </AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Profile</DropdownMenuItem>
              {userRole?.roleName === "admin" && (
                <DropdownMenuItem onClick={() => router.push("/admin")}>Admin</DropdownMenuItem>
              )}
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => signOut()}>
                <LogOut className="w-4 h-4 mr-2" />
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </nav>
  );
}

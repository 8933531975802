import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { GeistSans } from "geist/font/sans";

interface WorkflowDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  type: "create" | "edit";
  form: {
    title: string;
    content: string;
  };
  onFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
}

export function WorkflowDialog({
  isOpen,
  onOpenChange,
  type,
  form,
  onFormChange,
  onSubmit,
}: WorkflowDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader className={`${GeistSans.className}`}>
          <DialogTitle>
            {type === "create" ? "Create New" : "Edit"} Workflow
          </DialogTitle>
        </DialogHeader>
        <form
          onSubmit={onSubmit}
          className={`space-y-4 ${GeistSans.className}`}
        >
          <div className="space-y-2">
            <Label htmlFor="title">Title</Label>
            <Input
              id="title"
              name="title"
              value={form.title}
              onChange={onFormChange}
              placeholder="Enter workflow title"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="content">Content</Label>
            <Textarea
              id="content"
              name="content"
              value={form.content}
              onChange={onFormChange}
              placeholder="Enter workflow content"
              className="h-[60dvh]"
            />
          </div>
          <DialogFooter>
            <Button type="submit">
              {type === "create" ? "Create" : "Save"} Workflow
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
} 
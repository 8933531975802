import { create } from 'zustand';
import { getConversations, deleteConversation } from '../actions/conversation-actions';


export interface Conversation {
  id: string;
  userId: string;
  title: string;
  timestamp: Date;
}

interface ConversationStore {
  conversations: Conversation[];
  currentConversationId: string | null;
  hasMore: boolean;
  isLoading: boolean;
  fetchConversations: () => Promise<void>;
  loadMore: () => Promise<void>;
  setCurrentConversationId: (id: string) => void;
  deleteConversation: (conversationId: string, name: string) => Promise<void>;
}

export const useConversationStore = create<ConversationStore>((set, get) => ({
  conversations: [],
  currentConversationId: null,
  hasMore: true,
  isLoading: false,

  deleteConversation: async (conversationId: string, name: string) => {
    await deleteConversation(conversationId);
    set(state => ({
      conversations: state.conversations.filter(conversation => conversation.id !== conversationId)
    }));
  },
  
  fetchConversations: async () => {
    set({ isLoading: true });
    try {
      const conversations = await getConversations();
      set({ 
        conversations,
        hasMore: conversations.length >= 15
      });
    } finally {
      set({ isLoading: false });
    }
  },

  loadMore: async () => {
    const { conversations, hasMore, isLoading } = get();
    
    if (!hasMore || isLoading) return;
    
    set({ isLoading: true });
    try {
      const nextConversations = await getConversations(15, conversations.length);
      
      if (nextConversations.length < 15) {
        set({ hasMore: false });
      }
      
      set(state => ({
        conversations: [...state.conversations, ...nextConversations]
      }));
    } finally {
      set({ isLoading: false });
    }
  },

  setCurrentConversationId: (id: string) => set({ currentConversationId: id }),
}));

'use client';

import { createContext, useContext } from 'react';

interface ChatContextType {
  sendMessage: (message: string) => void;
}

export const ChatContext = createContext<ChatContextType | null>(null);

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}; 
"use client";

import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
interface CreateFormData {
  name: string;
  description: string;
  default_selected: boolean;
  type: string;
}

interface DataCreateModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit: (data: CreateFormData) => Promise<void>;
  isProcessing: boolean;
}

export function DataCreateModal({
  isOpen,
  onOpenChange,
  onSubmit,
  isProcessing,
}: DataCreateModalProps) {
  const [formData, setFormData] = React.useState<CreateFormData>({
    name: "",
    description: "",
    default_selected: false,
    type: "vector_store",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(formData);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Data Store</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Tabs
            defaultValue="vector_store"
            className="w-full"
            onValueChange={(value) => setFormData({ ...formData, type: value })}
          >
            <TabsList className="w-full">
              <TabsTrigger value="vector_store" className="w-full">
                Vector Store
              </TabsTrigger>
              <TabsTrigger value="graph_store" className="w-full">
                Graph Store
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              required
            />
          </div>
          <div className="flex items-center space-x-4">
            <Switch
              id="default_selected"
              checked={formData.default_selected}
              onCheckedChange={(checked) =>
                setFormData({ ...formData, default_selected: checked })
              }
            />
            <Label htmlFor="default_selected">Default Selected</Label>
          </div>
          <div className="flex justify-end">
            <Button type="submit" disabled={isProcessing}>
              {isProcessing ? "Processing..." : "Create Store"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

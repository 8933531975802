"use client";

import { useState, useRef, useCallback, useEffect, type JSX } from "react";
import type { EndpointsContext } from "./agent";
import { useActions } from "./utils/client";
import { LocalContext } from "./shared";
import { useScrollToBottom } from "../hooks/useScrollToBottom";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { ArrowUp, Paperclip, X } from "lucide-react";
import { GeistSans } from "geist/font/sans";
import { useDropzone } from "react-dropzone";
import { useModelStore } from "../store/modelStore";
import { useDataStore } from "../store/dataStore";
import { useSession } from "next-auth/react";
import ConversationMenu from "@/components/conversation-menu";
import { List } from "lucide-react";
import { useConversationStore } from "../store/conversationStore";
import { getConversationMessages } from "../actions/conversation-actions";
import { Conversation } from "../store/conversationStore";
import { Plus } from "lucide-react";
import { fileToBase64 } from "@/lib/utils";
import Markdown from "react-markdown";
import { FileUpload } from "@/components/file-upload";
import { Loader2, Workflow as WorkflowIcon } from "lucide-react";
import WorkflowBrowser from "@/components/workflows/workflow-browser";
import {
  ConversationFileDisplay,
  type FileDisplayRef,
} from "@/components/conversation-file-display";
import { useToolStore } from "../store/toolStore";
import { ChatContext } from "@/app/contexts/ChatContext";
import { saveAssistantMessage } from "@/app/actions/conversation-actions";
export default function GenerativeUIPage() {
  const { data } = useSession({
    required: true,
  });

  const actions = useActions<typeof EndpointsContext>();
  const { selectedModel } = useModelStore();
  const { selectedTools } = useToolStore();
  const { selectedStores } = useDataStore();
  const [elements, setElements] = useState<JSX.Element[]>([]);
  const [input, setInput] = useState("");
  const [fileCount, setFileCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const historyState = useState<[role: string, content: string][]>([]);
  const [history, setHistory] = historyState;

  const [containerRef, endRef] = useScrollToBottom<HTMLDivElement>();
  const formRef = useRef<HTMLFormElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const fileDisplayRef = useRef<FileDisplayRef>(null);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const textarea = e.currentTarget;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
      setInput(textarea.value);
    },
    [],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        onSubmit(e as any);
      }
    },
    [onSubmit],
  );

  const {
    conversations,
    currentConversationId,
    fetchConversations,
    setCurrentConversationId,
  } = useConversationStore();

  useEffect(() => {
    if (!currentConversationId) {
      setCurrentConversationId(crypto.randomUUID());
    }
    fetchConversations();
  }, [
    currentConversationId,
    data,
    fetchConversations,
    setCurrentConversationId,
  ]);

  const [files, setFiles] = useState<File[]>([]);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const [workflowId, setWorkflowId] = useState<string | null>(null);
  const workflowRegex = /{{workflow%%([\w-]+)%%([^}]+)}}/;
  const workflowMatch = input.match(workflowRegex);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    if (acceptedFiles[0] && acceptedFiles[0].type.startsWith("image/")) {
      fileToBase64(acceptedFiles[0]).then(({ base64, mimeType }) => {
        setPreviewUrl(`data:${mimeType};base64,${base64}`);
      });
    } else {
      console.log("acceptedFiles[0].type", acceptedFiles[0].type);
      for (const file of acceptedFiles) {
        console.log("file", file.name);
        console.log("file.type", file.type);
      }
      setPreviewUrl(null);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true, // Prevent opening file dialog on click
  });

  const removeFile = useCallback(() => {
    setFiles([]);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
  }, [previewUrl]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Load a selected conversation
  const loadConversation = useCallback(
    async (conversation: Conversation) => {
      try {
        setCurrentConversationId(conversation.id);

        // Fetch messages for the selected conversation
        const messages = await getConversationMessages(conversation.id);
        console.log("Fetched messages:", messages);

        // Update the history state
        setHistory(messages.map((msg) => [msg.role, msg.content]));

        // Clear existing elements and recreate them from the loaded messages
        // TODO: currently only saves & loads text messages, not streamed UI elements
        const newElements = messages.map((msg, index) => (
          <div className="flex flex-col gap-2" key={index}>
            {msg.role === "user" ? (
              // User message
              <div className="text-sm mx-3 mb-4 flex self-startrounded-lg font-semibold">
                {msg.content}
              </div>
            ) : (
              // Assistant message
              <div
                className={`${GeistSans.className} flex mx-3 flex-col gap-2 self-start mb-8`}
              >
                <Markdown>{msg.content}</Markdown>
              </div>
            )}
          </div>
        ));

        setElements(newElements);
        setInput("");

        if (endRef.current) {
          endRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        console.error("Error loading conversation:", error);
      }
    },
    [setCurrentConversationId, setHistory, setElements, setInput, endRef],
  );

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!input.trim() && !files.length) return;

    setIsLoading(true);

    const newElements = [...elements];

    let base64Image: string | null = null;
    let mimeType: string | null = null;
    if (files.length > 0 && files[0].type.startsWith("image/")) {
      const result = await fileToBase64(files[0]);
      base64Image = result.base64;
      mimeType = result.mimeType;
    }

    const element = await actions.agent({
      input,
      base64: base64Image ? `data:${mimeType};base64,${base64Image}` : null,
      model: selectedModel?.model || "gpt-4o",
      provider: selectedModel?.provider || "openai",
      conversationId: currentConversationId || "",
      chat_history: history,
      fileCount: fileCount,
      tools: selectedTools.map((tool) => tool.id),
      stores: selectedStores.map((store) => store.id),
      workflow_uuid: workflowId
        ? workflowId
        : workflowMatch
        ? workflowMatch[1]
        : null,
    });

    if (element === "unauthorized") {
      return;
    }

    let userInput = input;
    let workflowTitle = "";

    if (workflowMatch) {
      const [, workflowUuid, workflowTitleMatch] = workflowMatch;
      console.log("workflowUuid", workflowUuid);
      console.log("workflowTitle", workflowTitleMatch);
      userInput = userInput.replace(workflowMatch[0], "");
      workflowTitle = workflowTitleMatch;
      setWorkflowId(workflowUuid);
    }

    newElements.push(
      <div
        className="flex flex-col gap-2 message-container mb-8"
        key={`message-${Date.now()}`}
      >
        <div className="text-sm mx-3 mt-2 flex self-start rounded-lg font-semibold ">
          {workflowMatch ? (
            <>
              <div className="flex items-center gap-2">
                <WorkflowIcon className="w-4 h-4" />
                <span>{workflowTitle}</span>
              </div>
            </>
          ) : (
            userInput
          )}
        </div>
        {base64Image && mimeType && (
          <img
            src={`data:${mimeType};base64,${base64Image}`}
            alt="Uploaded"
            className="max-w-xs max-h-40 object-contain mb-2"
          />
        )}
        <div
          className={GeistSans.className + " flex flex-col gap-2 self-start"}
        >
          {element.ui}
        </div>
      </div>,
    );

    // consume the value stream to obtain the final string value
    // after which we can append to our chat history state
    (async () => {
      const lastEvent = await element.lastEvent;
      if (typeof lastEvent === "string") {
        setHistory((prev) => [
          ...prev,
          ["user", input + (base64Image ? " [Image attached]" : "")],
          ["assistant", lastEvent],
        ]);

        //save the conversation and message
        if (currentConversationId) {
          saveAssistantMessage(currentConversationId, lastEvent);
        } else {
          console.error("No conversation ID");
        }
        setIsLoading(false);
      }
    })();

    setElements(newElements);
    setInput("");
    removeFile();
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
    }
  }

  function createNewConversation() {
    setCurrentConversationId(crypto.randomUUID());
    setIsMenuOpen(false);
    setElements([]);
    setHistory([]);
  }

  function handleUploadComplete(docId: string) {
    fileDisplayRef.current?.refresh();
  }

  function handleFileCountChange(count: number) {
    setFileCount(count);
  }

  return (
    <ChatContext.Provider
      value={{
        sendMessage: (message: string) => {
          setInput(message);
          requestAnimationFrame(() => {
            formRef.current?.requestSubmit();
          });
        },
      }}
    >
      <div className="flex w-full">
        {/* Conversation Menu */}
        <ConversationMenu
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          conversations={conversations}
          onSelectConversation={loadConversation}
        />

        <Button
          onClick={createNewConversation}
          variant={"outline"}
          className="rounded-full h-7 w-7 p-0 top-3 fixed left-[3.35rem]"
        >
          <Plus size={14} />
        </Button>

        <div className="flex-grow h-screen overflow-y-auto">
          <div
            ref={containerRef}
            className="flex flex-col gap-4 items-stretch pb-4 overflow-y-auto pt-16"
          >
            <LocalContext.Provider
              value={(input) =>
                onSubmit({
                  preventDefault: () => {
                    console.log("preventDefault");
                  },
                } as any)
              }
            >
              <div className="flex flex-col gap-2 rounded-lg max-w-[1200px] w-full mx-auto ">
                {/* ShowWorkflow Browser if we dont have any messages */}
                {elements.length === 0 && <WorkflowBrowser />}
              </div>
              <div className="flex flex-col gap-2 rounded-lg max-w-[600px] w-full mx-auto pb-32">
                {elements}
              </div>
            </LocalContext.Provider>
            <div ref={endRef} />{" "}
            {/* This empty div acts as our scroll anchor */}
          </div>
          <div className="bottom-0 fixed my-auto left-0 right-0 flex justify-center ">
            <div className="relative">
              <ConversationFileDisplay
                ref={fileDisplayRef}
                conversationId={currentConversationId || ""}
                onFileCountChange={handleFileCountChange}
              />
            </div>
            <form
              className="flex flex-col gap-2 border border-border border-b-0 rounded-t-md p-2 bg-background relative"
              onSubmit={onSubmit}
              ref={formRef}
            >
              {previewUrl && (
                <div className="relative w-10 h-10 mb-2 p-1">
                  <img
                    src={previewUrl}
                    alt="Preview"
                    className="w-full h-full object-cover rounded-md"
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    className="absolute top-0 right-0 rounded-full h-5 w-5 p-0"
                    onClick={removeFile}
                  >
                    <X size={12} />
                  </Button>
                </div>
              )}
              <div className="flex gap-2" {...getRootProps()}>
                <div className="relative flex-grow w-[550px]">
                  {!workflowMatch && (
                    <Textarea
                      id="chat-input"
                      ref={textareaRef}
                      rows={3}
                      value={input}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      placeholder={
                        isDragActive
                          ? "Drop the files here ..."
                          : "Send a message..."
                      }
                      className="resize-none bg-transparent px-3 py-2 border-none shadow-none rounded-md text-foreground outline-none focus-visible:outline-0 focus-visible:ring-0"
                      style={{
                        minHeight: "70px",
                        maxHeight: "200px",
                      }}
                    />
                  )}
                  {workflowMatch && (
                    <Textarea
                      rows={3}
                      placeholder={"Starting workflow..."}
                      className="resize-none bg-transparent px-3 py-2 border-none shadow-none rounded-md text-foreground outline-none focus-visible:outline-0 focus-visible:ring-0"
                      style={{
                        minHeight: "70px",
                        maxHeight: "200px",
                      }}
                    />
                  )}
                  {files.length > 0 &&
                    files.map((file) => (
                      <FileUpload
                        key={file.name}
                        file={file}
                        conversationId={currentConversationId || ""}
                        onComplete={handleUploadComplete}
                      />
                    ))}
                </div>

                <div className="flex flex-col gap-2">
                  <Button
                    type="submit"
                    variant={"outline"}
                    className="rounded-full h-7 w-7 p-0 mt-1 mr-1"
                  >
                    {isLoading ? (
                      <Loader2 size={14} className="animate-spin" />
                    ) : (
                      <ArrowUp size={14} />
                    )}
                  </Button>
                  <Button
                    type="button"
                    variant={"ghost"}
                    className="rounded-full h-7 w-7 p-0"
                    onClick={() =>
                      document.getElementById("file-upload")?.click()
                    }
                  >
                    <Paperclip size={14} />
                  </Button>
                </div>
              </div>
              <input {...getInputProps()} id="file-upload" />
            </form>
          </div>
        </div>

        <div className="fixed top-0 left-0 mt-3 ml-4">
          <Button
            type="button"
            onClick={() => setIsMenuOpen(true)}
            variant={"outline"}
            className="rounded-full h-7 w-7 p-0 -mt-[1px]"
          >
            <List size={14} />
          </Button>
        </div>

        <div
          id="chat-canvas"
          className="overflow-x-visible border-l border-border h-screen overflow-y-auto pb-32 transition-all"
        ></div>
      </div>
    </ChatContext.Provider>
  );
}

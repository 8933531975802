import { create } from 'zustand';
import { persist, createJSONStorage } from "zustand/middleware";
import { Model } from '@/app/db/schema';

interface ModelState {
  selectedModel: Model | null;
  setSelectedModel: (model: Model | null) => void;
  models: Model[];
  setModels: (models: Model[]) => void;
}

export const useModelStore = create<ModelState>()(
  persist(
    (set, get) => ({
      selectedModel: null,
      models: [],
      setSelectedModel: (model) => set({ selectedModel: model }),
      setModels: (models) => {
        const { selectedModel } = get();
        set({ models });
        
        // Only set default model if there's no persisted selection
        if (!selectedModel && models.length > 0) {
          const lowestIdModel = models.reduce((prev, current) =>
            prev.id < current.id ? prev : current
          );
          set({ selectedModel: lowestIdModel });
        } else if (selectedModel) {
          // If we have a selected model, verify it still exists in the new models list
          const modelStillExists = models.find(m => m.id === selectedModel.id);
          if (!modelStillExists) {
            // If the previously selected model no longer exists, select the first available model
            set({ selectedModel: models[0] || null });
          } else {
            // Update the selected model data with the fresh data
            set({ selectedModel: modelStillExists });
          }
        }
      },
    }),
    {
      name: 'model-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        selectedModel: state.selectedModel,
      }),
    }
  )
);

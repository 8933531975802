"use client";

import { useState, useEffect, useRef } from "react";
import { Progress } from "@/components/ui/progress";
import { X } from "lucide-react";
import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";

interface FileUploadProps {
  file: File;
  conversationId?: string;
  vectorStoreId?: string;
  onComplete?: (docId: string) => void;
  onRemove?: () => void;
  className?: string;
}

export function FileUpload({
  file,
  conversationId,
  vectorStoreId,
  onComplete,
  onRemove,
  className,
}: FileUploadProps) {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const uploadStartedRef = useRef(false);

  const handleUpload = async () => {
    try {
      setIsUploading(true);
      setError(null);

      const formData = new FormData();
      formData.append("file", file);

      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setProgress(percentComplete);
        }
      };

      const response = await new Promise((resolve, reject) => {
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const response = JSON.parse(xhr.responseText);
              resolve(response);
            } catch {
              reject(new Error("Invalid response"));
            }
          } else {
            reject(new Error(`HTTP Error: ${xhr.status}`));
          }
        };
        xhr.onerror = () => reject(new Error("Network error"));

        xhr.upload.onloadend = () => {
          setIsUploading(false);
          setIsProcessing(true);
        };

        const url = conversationId
          ? `/api/upload?conversationId=${conversationId}`
          : `/api/upload?vectorStoreId=${vectorStoreId}`;

        xhr.open("POST", url);
        xhr.send(formData);
      });

      const result = response as { success: boolean; docId?: string };

      if (result.success && result.docId) {
        if (conversationId) {
          onComplete?.(result.docId);
        } else {
          onComplete?.(file.name);
        }
        setIsComplete(true);
      } else {
        throw new Error("Upload failed");
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "Upload failed");
      setProgress(0);
    } finally {
      setIsUploading(false);
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (!uploadStartedRef.current) {
      uploadStartedRef.current = true;
      handleUpload();
    }
  }, []);

  if (isComplete) {
    return null;
  }

  if (!isUploading && !isProcessing && !error) {
    return null;
  }

  return (
    <div className={cn("w-full", className)}>
      <div className="flex items-center justify-between mb-4 text-muted-foreground">
        <div
          className="text-xs mx-2 font-medium truncate max-w-[250px]"
          title={file.name}
        >
          {file.name}
        </div>
        {isUploading ? (
          <Progress value={progress} className="h-2 mx-2" />
        ) : isProcessing ? (
          <div className="flex items-center gap-2">
            <Loader2 className="h-4 w-4 animate-spin" />
            <span className="text-xs text-muted-foreground">
              Processing document...
            </span>
          </div>
        ) : null}
      </div>

      {error && <p className="text-sm text-destructive mb-4">{error}</p>}
    </div>
  );
}

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { VectorStore } from "@/app/db/schema";

interface DataStore {
  vectorStores: VectorStore[];
  selectedStores: VectorStore[];
  setVectorStores: (stores: VectorStore[]) => void;
  setSelectedStores: (selectedStores: VectorStore[]) => void;
  toggleStore: (store: VectorStore) => void;
  isStoreSelected: (storeId: number) => boolean;
  selectedStore?: VectorStore;
  setSelectedStore: (store?: VectorStore) => void;
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: (open: boolean) => void;
  isEditModalOpen: boolean;
  setIsEditModalOpen: (open: boolean) => void;
  isProcessing: boolean;
  setIsProcessing: (processing: boolean) => void;
}

export const useDataStore = create<DataStore>()(
  persist(
    (set, get) => ({
      vectorStores: [],
      selectedStores: [],
      selectedStore: undefined,
      isCreateModalOpen: false,
      isEditModalOpen: false,
      isProcessing: false,
      setVectorStores: (stores) => {
        const { selectedStores } = get();
        // If no stores are selected, use default selected ones
        if (selectedStores.length === 0) {
          set({ 
            vectorStores: stores,
            selectedStores: stores.filter(store => store.default_selected)
          });
        } else {
          // Keep existing selections, but update store data
          const updatedSelectedStores = selectedStores
            .map(selected => stores.find(s => s.id === selected.id))
            .filter((store): store is VectorStore => store !== undefined);
          set({ 
            vectorStores: stores,
            selectedStores: updatedSelectedStores
          });
        }
      },
      setSelectedStores: (selectedStores) => set({ selectedStores }),
      toggleStore: (store) => {
        const { selectedStores } = get();
        const isSelected = selectedStores.some(s => s.id === store.id);
        
        if (isSelected) {
          set({ selectedStores: selectedStores.filter(s => s.id !== store.id) });
        } else {
          set({ selectedStores: [...selectedStores, store] });
        }
      },
      isStoreSelected: (storeId) => {
        const { selectedStores } = get();
        return selectedStores.some(store => store.id === storeId);
      },
      setSelectedStore: (store) => set({ selectedStore: store }),
      setIsCreateModalOpen: (open) => set({ isCreateModalOpen: open }),
      setIsEditModalOpen: (open) => set({ isEditModalOpen: open }),
      setIsProcessing: (processing) => set({ isProcessing: processing }),
    }),
    {
      name: 'data-store',
      partialize: (state) => ({ 
        selectedStores: state.selectedStores 
      }),
    }
  )
); 
"use client";

import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { getModels } from "@/app/actions/model-actions";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useModelStore } from "@/app/store/modelStore";

export function ModelSelect() {
  const [open, setOpen] = React.useState(false);
  const { selectedModel, setSelectedModel, models, setModels } =
    useModelStore();

  React.useEffect(() => {
    getModels()
      .then((fetchedModels) => {
        setModels(fetchedModels);
      })
      .catch(console.error);
  }, [setModels]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className=" w-32 justify-between"
          size="sm"
        >
          <div className="flex w-full items-center justify-between gap-2">
            <div className="flex items-center gap-2 ">
              <div className="led h-2 w-2 bg-green-500 rounded-full blur-[1px] "></div>
              <div className="max-w-[60px] truncate">
                {selectedModel?.name || "Model"}
              </div>
            </div>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 text-sm">
        <Command>
          <CommandInput placeholder="Search model..." />
          <CommandList>
            <CommandEmpty>No model found.</CommandEmpty>
            <CommandGroup>
              {models.map((model) => (
                <CommandItem
                  key={model.id}
                  value={model.name}
                  onSelect={() => {
                    setSelectedModel(model);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedModel?.id === model.id
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  <div className="flex items-center gap-2">
                    <img
                      src={model.image_url || ""}
                      alt={model.name}
                      className={cn(
                        "w-4 h-4",
                        model.image_url?.endsWith('openai.svg') && "dark:brightness-0 dark:invert"
                      )}
                    />
                    {model.name}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

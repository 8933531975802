import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Tool } from "@/app/db/schema";

interface ToolStore {
  tools: Tool[];
  selectedTools: Tool[];
  setTools: (tools: Tool[]) => void;
  setSelectedTools: (selectedTools: Tool[]) => void;
  toggleTool: (tool: Tool) => void;
  isToolSelected: (toolId: number) => boolean;
}

export const useToolStore = create<ToolStore>()(
  persist(
    (set, get) => ({
      tools: [],
      selectedTools: [],
      setTools: (tools) => {
        const { selectedTools } = get();
        // If no tools are selected, use default selected ones
        if (selectedTools.length === 0) {
          set({ 
            tools,
            selectedTools: tools.filter(tool => tool.default_selected)
          });
        } else {
          // Keep existing selections, but update tool data
          const updatedSelectedTools = selectedTools
            .map(selected => tools.find(t => t.id === selected.id))
            .filter((tool): tool is Tool => tool !== undefined);
          set({ 
            tools,
            selectedTools: updatedSelectedTools
          });
        }
      },
      setSelectedTools: (selectedTools) => set({ selectedTools }),
      toggleTool: (tool) => {
        const { selectedTools } = get();
        const isSelected = selectedTools.some(t => t.id === tool.id);
        
        if (isSelected) {
          set({ selectedTools: selectedTools.filter(t => t.id !== tool.id) });
        } else {
          set({ selectedTools: [...selectedTools, tool] });
        }
      },
      isToolSelected: (toolId) => {
        const { selectedTools } = get();
        return selectedTools.some(tool => tool.id === toolId);
      },
    }),
    {
      name: 'tool-store',
      partialize: (state) => ({ 
        selectedTools: state.selectedTools 
      }),
    }
  )
); 
import React from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { X, Trash } from "lucide-react";
import { useEffect } from "react";
import {
  Conversation,
  useConversationStore,
} from "@/app/store/conversationStore";

interface ConversationMenuProps {
  isOpen: boolean;
  onClose: () => void;
  conversations: Conversation[];
  onSelectConversation: (conversation: Conversation) => void;
}

const ConversationMenu: React.FC<ConversationMenuProps> = ({
  isOpen,
  onClose,
  conversations,
  onSelectConversation,
}) => {
  const {
    fetchConversations,
    hasMore,
    loadMore,
    deleteConversation,
    isLoading,
  } = useConversationStore();

  useEffect(() => {
    if (isOpen) {
      fetchConversations();
    }
  }, [isOpen, fetchConversations]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-y-0 left-0 w-80 bg-background border-r border-border z-50 shadow-lg">
      <div className="flex justify-between items-center p-4 border-b border-border">
        <h2 className="text-lg font-semibold">Conversations</h2>
        <Button
          onClick={onClose}
          size="sm"
          variant={"outline"}
          className="rounded-full h-7 w-7 p-0 "
        >
          <X size={14} />
        </Button>
      </div>
      <ScrollArea className="h-[calc(100vh-64px)] p-2">
        {conversations.map((conversation) => (
          <div
            key={conversation.id}
            className="mr-1 rounded-md  flex flex-row gap-4 justify-start text-left px-4 py-2 hover:bg-accent truncate w-full"
          >
            <Button
              onClick={() => {
                onSelectConversation(conversation);
                onClose();
              }}
              variant="ghost"
              className="p-0 text-left w-10/12"
            >
              <div className="truncate py-2 w-full">
                <div className="text-sm font-medium truncate w-full">
                  {conversation.title}
                </div>
                <div className="text-xs text-muted-foreground truncate">
                  {conversation.timestamp.toLocaleString()}
                </div>
              </div>
            </Button>
            <div className="w-2/12 flex justify-end">
              <Button
                onClick={() =>
                  deleteConversation(conversation.id, conversation.title)
                }
                variant="ghost"
                className="rounded-full h-7 w-7 p-0"
              >
                <Trash size={14} />
              </Button>
            </div>
          </div>
        ))}
        {hasMore && (
          <div className="flex justify-center items-center">
            <Button
              onClick={loadMore}
              variant="outline"
              className=" mx-auto mt-4 text-muted-foreground  "
            >
              {isLoading ? "Loading..." : "Load More"}
            </Button>
          </div>
        )}
      </ScrollArea>
    </div>
  );
};

export default ConversationMenu;

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { GeistSans } from "geist/font/sans";

interface FolderDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  folderName: string;
  onFolderNameChange: (name: string) => void;
  onSubmit: (e: React.FormEvent) => void;
}

export function FolderDialog({
  isOpen,
  onOpenChange,
  title,
  folderName,
  onFolderNameChange,
  onSubmit,
}: FolderDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader className={`${GeistSans.className}`}>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <form
          onSubmit={onSubmit}
          className={`space-y-4 ${GeistSans.className}`}
        >
          <div className="space-y-2">
            <Label htmlFor="folderName">Folder Name</Label>
            <Input
              id="folderName"
              value={folderName}
              onChange={(e) => onFolderNameChange(e.target.value)}
              placeholder="Enter folder name"
            />
          </div>
          <Button type="submit" className={`float-right`}>
            {title}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
} 
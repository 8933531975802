"use client";

import * as React from "react";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import { useState, useEffect } from "react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";

const FONT_SIZE_KEY = 'app-font-size';

export function ThemeToggle() {
  const { setTheme } = useTheme();
  const [fontSize, setFontSize] = useState(16); // Default will be overridden by useEffect

  // Function to update font size
  const adjustFontSize = (increment: number) => {
    const newSize = fontSize + increment;
    if (newSize >= 12 && newSize <= 24) { // Set min and max limits
      setFontSize(newSize);
      document.documentElement.style.fontSize = `${newSize}px`;
      localStorage.setItem(FONT_SIZE_KEY, newSize.toString());
    }
  };

  // Initialize font size from localStorage on component mount
  useEffect(() => {
    // Get saved font size or default to 16
    const savedFontSize = Number(localStorage.getItem(FONT_SIZE_KEY)) || 16;
    setFontSize(savedFontSize);
    document.documentElement.style.fontSize = `${savedFontSize}px`;
  }, []);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className="rounded-full h-7 w-7 ">
          <Sun
            size={14}
            className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"
          />
          <Moon
            size={14}
            className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"
          />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme("light")}>
          Light
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("dark")}>
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("system")}>
          System
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <div className="px-2 py-1.5 text-sm flex items-center justify-between">
          Text Size
          <div className="flex gap-2 ml-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => adjustFontSize(-1)}
              className="h-6 w-6 p-0"
            >
              -
            </Button>
            <span className="mx-1">{fontSize}</span>
            <Button
              variant="outline"
              size="sm"
              onClick={() => adjustFontSize(1)}
              className="h-6 w-6 p-0"
            >
              +
            </Button>
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

"use client";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { FileIcon } from "lucide-react";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {
  getConversationDocuments,
} from "@/app/actions/document-actions";
import { Badge } from "@/components/ui/badge";
import { ChatVector } from "@/app/db/schema";

interface ConversationFileDisplayProps {
  conversationId: string;
  onFileCountChange: (count: number) => void;
}

export interface FileDisplayRef {
  refresh: () => Promise<void>;
}

export const ConversationFileDisplay = forwardRef<FileDisplayRef, ConversationFileDisplayProps>(
  ({ conversationId, onFileCountChange }, ref) => {
    const [documents, setDocuments] = useState<ChatVector[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [hasDocuments, setHasDocuments] = useState(false);

    const fetchDocuments = async () => {
      try {
        const docs = await getConversationDocuments(conversationId);
        const uniqueDocs = Array.from(
          new Map(docs.map((item) => [item.document, item])).values(),
        );
        setDocuments(uniqueDocs);
        setHasDocuments(uniqueDocs.length > 0);
        onFileCountChange(uniqueDocs.length);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    useImperativeHandle(ref, () => ({
      refresh: fetchDocuments
    }));

    useEffect(() => {
      fetchDocuments();
    }, [conversationId]);

    if (!hasDocuments) {
      return null;
    }

    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant="ghost" size="icon" className="h-7 w-7 rounded-full border absolute top-0 -left-10 bg-background">
            <FileIcon size={14} />
            <Badge className="absolute top-4 left-4 rounded-full h-4 w-4 p-0 flex items-center justify-center text-xs">
              {documents.length}
            </Badge>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Uploaded Documents </DialogTitle>
            <span className="text-sm text-muted-foreground">for this conversation</span>
          </DialogHeader>
          <div className="flex flex-col gap-2 mt-4">
            {documents.map((doc, index) => (
              <div
                key={index}
                className="flex items-center gap-2"
              >
                <FileIcon className="h-4 w-4" />
                <span className="text-sm">{doc.document}</span>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

ConversationFileDisplay.displayName = "ConversationFileDisplay";

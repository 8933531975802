import { Workflow } from "@/app/db/schema";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Workflow as WorkflowIcon, EllipsisVertical, Pencil, Trash2 } from "lucide-react";
import { GeistSans } from "geist/font/sans";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface WorkflowItemProps {
  workflow: Workflow;
  onWorkflowClick: (content: string, uuid: string, title: string) => void;
  onEditClick: (workflow: Workflow) => void;
  onDeleteClick: (workflow: Workflow) => void;
}

export function WorkflowItem({ workflow, onWorkflowClick, onEditClick, onDeleteClick }: WorkflowItemProps) {
  return (
    <div>
      <Card className="bg-transparent shadow-none border-none px-0 py-1 rounded-none transition-colors group">
        <div className="flex items-center justify-between">
          <div
            className="flex items-center space-x-2 cursor-pointer group-hover:opacity-80 transition-opacity rounded-md px-2 py-1"
            onClick={() => onWorkflowClick(workflow.content, workflow.uuid, workflow.title)}
          >
            <WorkflowIcon className="h-4 w-4" />
            <span className={`${GeistSans.className}`}>
              {workflow.title}
            </span>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="opacity-0 group-hover:opacity-100">
                <EllipsisVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => onEditClick(workflow)}>
                <Pencil className="h-4 w-4 mr-2" />
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                className="text-destructive"
                onClick={() => onDeleteClick(workflow)}
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </Card>
      <Separator />
    </div>
  );
} 
"use client";

import { ReactNode, useContext } from "react";
import { createContext } from "react";

// Define a type for the context value
type ActionsContextType = Record<string, any> | null;

// Initialize context with a default value
const ActionsContext = createContext<ActionsContextType>(null);

/**
 * Internal provider exposing serialised React Actions
 * This needs to be consumed in the layout of the route
 */
export const AIProvider = (props: {
  actions: Record<string, any>;
  children: ReactNode;
}) => {
  console.log("AIProvider children:", props.children);

  if (!props.actions) {
    console.warn("AIProvider: No actions provided");
    if (!props.children) {
      return <div></div>;
    }
    return <div>{props.children}</div>;
  }

  if (!props.children) {
    return <div></div>;
  }

  return (
    <ActionsContext.Provider value={props.actions}>
      {props.children}
    </ActionsContext.Provider>
  );
};

/**
 * Use actions exposes via `exposeEndpoints` function in `@/app/generative_ui/utils/server.tsx`
 * Reason why we need this is because we can't import components as such due to NextJS limitations:
 *
 * server (layout) --> client (page) --> server (action) -x-> client (components for llm)
 *
 * See https://github.com/vercel/next.js/pull/59615
 */
export function useActions<T extends { $$types?: Record<string, unknown> }>() {
  const context = useContext(ActionsContext);

  if (context === null) {
    throw new Error("useActions must be used within an AIProvider");
  }

  return context as Exclude<T["$$types"], undefined>;
}

import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Separator } from "@/components/ui/separator";

export function FolderSkeleton() {
  return (
    <Card className="p-4 shadow-none">
      <div className="flex items-center space-x-4 px-2 py-1">
        <Skeleton className="h-4 w-4" />
        <Skeleton className="h-4 w-[100px]" />
      </div>
    </Card>
  );
}

export function WorkflowSkeleton() {
  return (<></>
    // <div>
    //   <Card className="shadow-none border-none px-2 py-1 rounded-none">
    //     <div className="flex items-center justify-between">
    //       <div className="flex items-center space-x-2">
    //         <Skeleton className="h-4 w-4" />
    //         <Skeleton className="h-4 w-[150px]" />
    //       </div>
    //     </div>
    //   </Card>
    //   <Separator />
    // </div>
  );
} 
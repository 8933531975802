"use client";

import { useState, useEffect } from "react";
import { WorkflowFolder, Workflow } from "@/app/db/schema";
import { useChat } from "@/app/contexts/ChatContext";
import {
  createFolder,
  createWorkflow,
  getFolderContents,
  getFolderPath,
  deleteFolder,
  deleteWorkflow,
  updateWorkflow,
  updateFolder,
} from "@/app/actions/workflow-actions";
import { useRouter } from "next/navigation";
import { Plus, EllipsisVertical } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FolderItem } from "./components/folder-item";
import { WorkflowItem } from "./components/workflow-item";
import { Breadcrumbs } from "./components/breadcrumbs";
import { DeleteDialog } from "./components/delete-dialog";
import { FolderDialog } from "./components/folder-dialog";
import { WorkflowDialog } from "./components/workflow-dialog";
import { FolderSkeleton, WorkflowSkeleton } from "./components/loading-skeletons";

export default function WorkflowBrowser() {
  const [currentFolderId, setCurrentFolderId] = useState<number | null>(null);
  const [contents, setContents] = useState<{
    folders: WorkflowFolder[];
    workflows: Workflow[];
  }>({ folders: [], workflows: [] });
  const [breadcrumbs, setBreadcrumbs] = useState<WorkflowFolder[]>([]);
  const [isCreateFolderOpen, setIsCreateFolderOpen] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    type: "folder" | "workflow";
    id: number;
    name: string;
  } | null>(null);
  const [isEditFolderOpen, setIsEditFolderOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<{
    type: "folder" | "workflow";
    id: number;
    name: string;
  } | null>(null);
  const [workflowModal, setWorkflowModal] = useState<{
    type: "create" | "edit";
    isOpen: boolean;
    workflow?: Workflow;
  }>({
    type: "create",
    isOpen: false,
  });
  const [workflowForm, setWorkflowForm] = useState({
    title: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();
  const { sendMessage } = useChat();

  useEffect(() => {
    loadFolder(null);
  }, []);

  const loadFolder = async (folderId: number | null) => {
    setIsLoading(true);
    try {
      const [newContents, path] = await Promise.all([
        getFolderContents(folderId),
        getFolderPath(folderId),
      ]);
      setContents(newContents);
      setBreadcrumbs(path);
      setCurrentFolderId(folderId);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateFolder = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newItemName) return;

    await createFolder(newItemName, currentFolderId);
    setNewItemName("");
    setIsCreateFolderOpen(false);
    loadFolder(currentFolderId);
  };

  const handleEditFolder = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newItemName || !itemToEdit) return;

    await updateFolder(itemToEdit.id, newItemName);
    setNewItemName("");
    setIsEditFolderOpen(false);
    setItemToEdit(null);
    loadFolder(currentFolderId);
  };

  const handleDelete = async () => {
    if (!itemToDelete) return;

    try {
      if (itemToDelete.type === "folder") {
        await deleteFolder(itemToDelete.id);
      } else {
        await deleteWorkflow(itemToDelete.id);
      }
      loadFolder(currentFolderId);
    } catch (error) {
      console.error("Delete failed:", error);
    }
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  const handleWorkflowFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setWorkflowForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleWorkflowSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!workflowForm.title) return;

    if (workflowModal.type === "create" && currentFolderId) {
      await createWorkflow(
        workflowForm.title,
        currentFolderId,
        workflowForm.content,
      );
    } else if (workflowModal.type === "edit" && workflowModal.workflow) {
      await updateWorkflow(workflowModal.workflow.id, workflowForm);
    }

    setWorkflowForm({ title: "", content: "" });
    setWorkflowModal({ type: "create", isOpen: false });
    loadFolder(currentFolderId);
  };

  useEffect(() => {
    if (workflowModal.type === "edit" && workflowModal.workflow) {
      setWorkflowForm({
        title: workflowModal.workflow.title,
        content: workflowModal.workflow.content,
      });
    } else {
      setWorkflowForm({ title: "", content: "" });
    }
  }, [workflowModal]);

  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          onBreadcrumbClick={loadFolder}
        />
        <div className="flex space-x-4 mb-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <EllipsisVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => setIsCreateFolderOpen(true)}>
                <Plus className="h-4 w-4 mr-2" />
                New Folder
              </DropdownMenuItem>
              {currentFolderId && (
                <DropdownMenuItem
                  onClick={() => setWorkflowModal({ type: "create", isOpen: true })}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  New Workflow
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {isLoading ? (
          <>
            <FolderSkeleton />
            <FolderSkeleton />
            <FolderSkeleton />
          </>
        ) : (
          contents.folders.map((folder) => (
            <FolderItem
              key={folder.id}
              folder={folder}
              onFolderClick={loadFolder}
              onEditClick={(folder) => {
                setNewItemName(folder.name);
                setItemToEdit({ type: "folder", id: folder.id, name: folder.name });
                setIsEditFolderOpen(true);
              }}
              onDeleteClick={(folder) => {
                setItemToDelete({
                  type: "folder",
                  id: folder.id,
                  name: folder.name,
                });
                setDeleteDialogOpen(true);
              }}
            />
          ))
        )}
      </div>

      <div className="flex flex-col gap-4 mt-4">
        {isLoading ? (
          <>
            <WorkflowSkeleton />
            <WorkflowSkeleton />
            <WorkflowSkeleton />
          </>
        ) : (
          contents.workflows.map((workflow) => (
            <WorkflowItem
              key={workflow.id}
              workflow={workflow}
              onWorkflowClick={(content, uuid, title) =>
                sendMessage("{{workflow%%" + uuid + "%%" + title + "}}" + content)
              }
              onEditClick={(workflow) =>
                setWorkflowModal({
                  type: "edit",
                  isOpen: true,
                  workflow,
                })
              }
              onDeleteClick={(workflow) => {
                setItemToDelete({
                  type: "workflow",
                  id: workflow.id,
                  name: workflow.title,
                });
                setDeleteDialogOpen(true);
              }}
            />
          ))
        )}
      </div>

      <DeleteDialog
        isOpen={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        itemToDelete={itemToDelete}
        onConfirm={handleDelete}
      />

      <FolderDialog
        isOpen={isCreateFolderOpen}
        onOpenChange={setIsCreateFolderOpen}
        title="Create New Folder"
        folderName={newItemName}
        onFolderNameChange={setNewItemName}
        onSubmit={handleCreateFolder}
      />

      <FolderDialog
        isOpen={isEditFolderOpen}
        onOpenChange={setIsEditFolderOpen}
        title="Edit Folder"
        folderName={newItemName}
        onFolderNameChange={setNewItemName}
        onSubmit={handleEditFolder}
      />

      <WorkflowDialog
        isOpen={workflowModal.isOpen}
        onOpenChange={(isOpen) =>
          setWorkflowModal((prev) => ({ ...prev, isOpen }))
        }
        type={workflowModal.type}
        form={workflowForm}
        onFormChange={handleWorkflowFormChange}
        onSubmit={handleWorkflowSubmit}
      />
    </div>
  );
}
